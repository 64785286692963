<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>
  <div v-else class="elv-table-text">
    <div v-if="props.params?.value" class="elv-table-text__content">
      {{ formatTextValue }}
    </div>
    <div v-else class="elv-table-text__content">-</div>
  </div>
</template>
<script lang="ts" setup>
import GroupCell from '@/components/Reports/Cell/GroupCell.vue'

const props: any = defineProps<{ params: any }>()

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const formatTextValue = computed(() => {
  switch (props.params.value?.cellName) {
    case 'Address Name':
      return props.params?.data?.addressName
    case 'Name':
      return props.params?.data?.name
    case 'Journal':
      return props.params?.data?.journal?.journalType?.name
    case 'Memo':
      if (props.params?.node?.level === 0) return ''
      return props.params?.data?.memo ? props.params?.data?.memo : '-'
    default:
      return props.params?.data?.description
  }
})
</script>
<style lang="scss">
.elv-table-text {
  display: flex;
  align-items: center;
  text-align: center;

  svg {
    margin-right: 8px;
  }
}

.elv-table-text__content {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 13px;
  color: $elv-theme-base-text-color;
  white-space: nowrap;
}
</style>
