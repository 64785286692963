import DateCell from '@/pages/Financials/Project/Ledger/components/Cell/DateCell.vue'

const config = {
  cellRenderer: DateCell
}

export default {
  type: 'Date',
  config
}
