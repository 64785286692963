import TextCell from '@/pages/Financials/Project/Ledger/components/Cell/TextCell.vue'

const config = {
  cellRenderer: TextCell
}

export default {
  type: 'Text',
  config
}
