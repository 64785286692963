<template>
  <template v-if="props.params.node?.group">
    <div class="elv-ledger-cell-currency-list">
      <template v-if="props.params?.data?.currencyList?.length > 0">
        <template v-for="(item, index) in props.params?.data?.currencyList?.slice(0, 3)" :key="index">
          <img
            v-if="item.logo"
            :src="item.logo"
            :alt="item.showSymbol"
            :onerror="useDefaultImage"
            class="elv-ledger-cell-currency-main__logo"
          />
          <img
            v-else
            src="@/assets/img/default-logo.png"
            alt=""
            :onerror="useDefaultImage"
            class="elv-ledger-cell-currency-main__logo"
          />
        </template>
        <div v-if="props.params?.data?.currencyList?.length > 3" class="elv-ledger-cell-currency-item__title">
          +{{ props.params?.data?.currencyList?.length - 3 }}
        </div>
      </template>
    </div>
  </template>
  <template v-else>
    <div class="elv-ledger-cell-currency-main">
      <img
        v-if="props.params?.data?.currency?.logo"
        :src="props.params?.data?.currency?.logo"
        :alt="props.params?.data?.currency?.showSymbol"
        :onerror="useDefaultImage"
        class="elv-ledger-cell-currency-main__logo"
      />
      <img
        v-else
        src="@/assets/img/default-logo.png"
        alt=""
        :onerror="useDefaultImage"
        class="elv-ledger-cell-currency-main__logo"
      />
      <div class="elv-ledger-cell-currency-main__title">{{ props.params?.data?.currency?.showSymbol }}</div>
    </div>
  </template>
</template>
<script lang="ts" setup>
import defaultImg from '@/assets/img/default-logo.png'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>
<style lang="scss">
.elv-ledger-cell-currency-list {
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 4px;
    border-radius: 50%;
  }

  .elv-ledger-cell-currency-item__title {
    display: flex;
    align-items: center;
    color: #000;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.elv-ledger-cell-currency-main {
  display: flex;
  align-items: center;

  .elv-ledger-cell-currency-main__logo {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
  }

  .elv-ledger-cell-currency-main__title {
    font-family: 'Barlow';
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #0e0f11;
    padding-left: 4px;
  }
}
</style>
