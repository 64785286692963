<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'bottom'"
    class="elv-ledger-cell-status-main"
  >
    {{ t('common.posted') }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
</script>

<style lang="scss">
.elv-ledger-cell-status-main {
  display: flex;
  height: 22px;
  padding: 0px 8px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 14px;
  background: #f1f8ea;
  color: #41780c;
  border: 1px solid #edf0f3;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
