<template>
  <div class="elv-ledger-cell-topBottom-main">
    <div class="elv-ledger-cell-topBottom-content">
      <template v-if="props.params?.value?.cellName === 'Debit'">
        <div
          v-if="
            ((props.params?.data?.debitFC === null ||
              props.params?.data?.debit === null ||
              props.params?.data?.debitFC === 0 ||
              props.params?.data?.debit === 0) &&
              props.params?.node?.group) ||
            (String(props.params?.data?.debit) === '0' &&
              String(props.params?.data?.debitFC) === '0' &&
              props.params?.value?.tableType === 'generalLedger' &&
              !props.params?.node?.group)
          "
          class="elv-ledger-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.debitFC">
          <div
            class="elv-ledger-cell-topBottom-content__top-header"
            :title="`${fieldValueFormat(
              props.params?.data?.debitFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.debitFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
          </div>
        </template>
        <template v-else>
          <div
            class="elv-ledger-cell-topBottom-content__top"
            :title="`${fieldValueFormat(
              props.params?.data?.debitFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.debitFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
          </div>

          <div
            v-if="props.params?.data?.chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'"
            class="elv-ledger-cell-topBottom-content__bottom"
            :title="`${formatNumber(props.params?.data?.debit, 20)} ${props.params?.data?.currency?.showSymbol}`"
          >
            {{ formatNumberToSignificantDigits(props.params?.data?.debit, 2, '', false) }}
            {{ props.params?.data?.currency?.showSymbol }}
          </div>
        </template>
      </template>
      <template v-if="props.params?.value?.cellName === 'Credit'">
        <div
          v-if="
            ((props.params?.data?.creditFC === null ||
              props.params?.data?.credit === null ||
              props.params?.data?.creditFC === 0 ||
              props.params?.data?.credit === 0) &&
              props.params?.node?.group) ||
            (String(props.params?.data?.credit) === '0' &&
              String(props.params?.data?.creditFC) === '0' &&
              props.params?.value?.tableType === 'generalLedger' &&
              !props.params?.node?.group)
          "
          class="elv-ledger-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.creditFC">
          <div
            class="elv-ledger-cell-topBottom-content__top-header"
            :title="`${fieldValueFormat(
              props.params?.data?.creditFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.creditFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
            <!-- {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.debitFC, 2) }} -->
          </div>
        </template>
        <template v-else>
          <div
            class="elv-ledger-cell-topBottom-content__top"
            :title="`${fieldValueFormat(
              props.params?.data?.creditFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.creditFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
            <!-- {{ formatNumber(props.params?.data?.debitFC, 2) }} -->
          </div>
          <div
            v-if="props.params?.data?.chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'"
            class="elv-ledger-cell-topBottom-content__bottom"
            :title="`${formatNumber(props.params?.data?.credit, 20)} ${props.params?.data?.currency?.showSymbol}`"
          >
            {{ formatNumberToSignificantDigits(props.params?.data?.credit, 2, '', false) }}
            {{ props.params?.data?.currency?.showSymbol }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

// eslint-disable-next-line no-unused-vars
const { t } = useI18n()
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const cellHeight = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '52px' : 'auto'
})

const cellMarginTop = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '6px' : '0px'
})
</script>
<style lang="scss">
.elv-ledger-cell-topBottom-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: v-bind('cellHeight');
  margin-top: v-bind('cellMarginTop');

  .elv-ledger-cell-topBottom-content {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: right;

    .elv-ledger-cell-topBottom-content__top-header {
      font-family: 'Barlow';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
    }

    .elv-ledger-cell-topBottom-content__top {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
    }

    .elv-ledger-cell-topBottom-content__bottom {
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      color: $elv-color-838d95;
    }
  }

  .elv-ledger-cell-topBottom-content__bottom-color-zoom {
    color: $elv-theme-tertiary-text-color;
    zoom: 0.91;
    -moz-transform: scale(0.91);
    -moz-transform-origin: top left;
  }

  .elv-ledger-cell-topBottom-main__empty {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    text-align: right;
  }
}
</style>
