<template>
  <div v-if="props.params.node?.rowPinned !== 'bottom'" class="elv-ledger-cell-balance">
    <template v-if="props.params?.value?.cellName === 'Opening Balance'">
      <div
        :class="[
          props.params?.node?.group
            ? 'elv-ledger-cell-balance-main__Bold elv-ledger-cell-balance-main'
            : 'elv-ledger-cell-balance-main elv-ledger-cell-balance-main__Medium',
          props.params?.value?.tableType === 'generalLedger' &&
          types.includes(props.params?.data?.chartOfAccount?.type) &&
          !props.params?.node?.group
            ? 'flex-end'
            : ''
        ]"
        :style="{
          'justify-content':
            props.params?.data?.openingBalanceFC !== null && props.params?.data?.openingBalanceFC !== ''
              ? 'space-between'
              : ' flex-end'
        }"
      >
        <template v-if="props.params?.data?.openingBalanceFC !== null && props.params?.data?.openingBalanceFC !== ''">
          <p v-if="props.params?.node?.group">
            {{
              Big(props.params?.data?.openingBalanceFC).lt(0)
                ? props.params?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.data?.defaultBalanceType === 'Dr'
                  ? 'Cr'
                  : 'Dr'
                : (props.params?.data?.chartOfAccount?.defaultBalanceType ??
                  props.params?.data?.defaultBalanceType ??
                  props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType)
            }}
          </p>
          <p
            v-else-if="
              (props.params?.value?.tableType === 'generalLedger' &&
                !types.includes(props.params?.data?.chartOfAccount?.type)) ||
              props.params?.value?.tableType === 'accountBalance'
            "
          >
            {{
              Big(props.params?.data?.openingBalanceFC).lt(0)
                ? props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.node?.parent?.data?.defaultBalanceType === 'Dr'
                  ? 'Cr'
                  : 'Dr'
                : (props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType ??
                  props.params?.node?.parent?.data?.defaultBalanceType ??
                  props.params?.data?.chartOfAccount?.defaultBalanceType)
            }}
          </p>
        </template>
        <span
          style="padding-right: 2px"
          :title="`${fieldValueFormat(
            props.params?.data?.openingBalanceFC,
            {
              price: true,
              keepPoint: true,
              symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            },
            'NUMBER'
          )}`"
        >
          <template
            v-if="
              props.params?.value?.tableType === 'generalLedger' &&
              types.includes(props.params?.data?.chartOfAccount?.type) &&
              !props.params?.node?.group
            "
            >-
          </template>
          <template v-else>
            {{
              props.params?.data?.openingBalanceFC !== null && props.params?.data?.openingBalanceFC !== ''
                ? formatNumberToSignificantDigits(
                    props.params?.data?.openingBalanceFC,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            }}</template
          >
        </span>
      </div>
    </template>
    <template v-else-if="props.params?.value?.cellName === 'Closing Balance'">
      <div
        :class="[
          props.params?.node?.group
            ? 'elv-ledger-cell-balance-main__Bold elv-ledger-cell-balance-main'
            : 'elv-ledger-cell-balance-main elv-ledger-cell-balance-main__Medium',
          props.params?.value?.tableType === 'generalLedger' &&
          types.includes(props.params?.data?.chartOfAccount?.type) &&
          !props.params?.node?.group
            ? 'flex-end'
            : ''
        ]"
        :style="{
          'justify-content':
            props.params?.data?.closingBalanceFC !== null && props.params?.data?.closingBalanceFC !== ''
              ? 'space-between'
              : ' flex-end'
        }"
      >
        <template v-if="props.params?.data?.closingBalanceFC !== null && props.params?.data?.closingBalanceFC !== ''">
          <p v-if="props.params?.node?.group">
            {{
              Big(props.params?.data?.closingBalanceFC).lt(0)
                ? props.params?.value?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.value?.defaultBalanceType === 'Dr'
                  ? 'Cr'
                  : 'Dr'
                : (props.params?.value?.chartOfAccount?.defaultBalanceType ??
                  props.params?.value?.defaultBalanceType ??
                  props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType)
            }}
          </p>
          <p
            v-else-if="
              (props.params?.value?.tableType === 'generalLedger' &&
                !types.includes(props.params?.data?.chartOfAccount?.type)) ||
              props.params?.value?.tableType === 'accountBalance'
            "
          >
            {{
              Big(props.params?.data?.closingBalanceFC).lt(0)
                ? props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.data?.chartOfAccount?.defaultBalanceType === 'Dr' ||
                  props.params?.node?.parent?.data?.defaultBalanceType === 'Dr'
                  ? 'Cr'
                  : 'Dr'
                : props.params?.node?.parent?.data?.chartOfAccount?.defaultBalanceType ||
                  props.params?.node?.parent?.data?.defaultBalanceType ||
                  props.params?.data?.chartOfAccount?.defaultBalanceType
            }}
          </p>
        </template>

        <span
          style="padding-right: 2px"
          :title="`${fieldValueFormat(
            props.params?.data?.closingBalanceFC,
            {
              price: true,
              keepPoint: true,
              symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            },
            'NUMBER'
          )}`"
        >
          <template
            v-if="
              props.params?.value?.tableType === 'generalLedger' &&
              types.includes(props.params?.data?.chartOfAccount?.type) &&
              !props.params?.node?.group
            "
            >-</template
          >
          <template v-else>
            {{
              props.params?.data?.closingBalanceFC !== null && props.params?.data?.closingBalanceFC !== ''
                ? formatNumberToSignificantDigits(
                    props.params?.data?.closingBalanceFC,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            }}
          </template></span
        >
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import Big from 'big.js'
import { useEntityStore } from '@/stores/modules/entity'
import { fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

const types = ['REVENUE', 'EXPENSE', 'OTHER']
const props: any = defineProps<{ params: any }>()

const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
</script>
<style lang="scss">
.elv-ledger-cell-balance {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.elv-ledger-cell-balance-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: $elv-color-0E1420;
  text-align: right;
  width: 100%;

  &.flex-end {
    justify-content: flex-end !important;
  }

  p {
    color: #1753eb;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.elv-ledger-cell-balance-main__Bold {
  font-family: 'Barlow';
  font-weight: 600;
}

.elv-ledger-cell-balance-main__Medium {
  font-family: 'Barlow';
  font-weight: 500;
}
</style>
