<template>
  <template v-if="isGroup">
    <div class="elv-financials-ledger-cell-group">
      {{ groupTitle }}
    </div>
  </template>

  <template v-else>
    <div class="elv-financials-ledger-account-cell-currency">
      <img :src="props.params?.data?.currency?.logo" :alt="props.params?.data?.currency?.name" />
      {{ props.params?.data?.currency?.showSymbol }}
    </div>
  </template>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { locale } = useI18n()
dayjs.extend(utc)
dayjs.extend(timezone)

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const groupTitle = computed(() => {
  if (props.params?.data?.chartOfAccount?.name) {
    return locale.value === 'en'
      ? props.params?.data?.chartOfAccount?.name
      : props.params?.data?.chartOfAccount?.nameCN || props.params?.data?.chartOfAccount?.name
  }
  if (props.params?.data?.entityAccount?.name || props.params?.data?.entityAccount?.isUnnamed) {
    return props.params?.data?.entityAccount?.name
      ? `Account: ${props.params?.data?.entityAccount?.name}`
      : 'Uncategorized'
  }
  if (props.params?.data?.counterparty?.name || props.params?.data?.counterparty?.isUnnamed) {
    return props.params?.data?.counterparty?.name ? `${props.params?.data?.counterparty?.name}` : 'Uncategorized'
  }
  if (props.params?.data?.auxiliaryItem?.value || props.params?.data?.auxiliaryItem?.isUnnamed) {
    return props.params?.data?.auxiliaryItem?.value || 'Uncategorized'
  }
  return props.params?.data?.name
})
</script>
<style lang="scss">
.elv-financials-ledger-cell-group {
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.elv-financials-ledger-account-cell-currency {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
  }
}
</style>
