<template>
  <template v-if="isGroup">
    <div class="elv-financials-ledger-cell-group">
      {{ props.params?.data?.chartOfAccount?.name ?? props.params?.data?.name }}
    </div>
  </template>
  <div v-else-if="props.params?.value?.cellName !== 'Chart of account'" class="elv-ledger-cell-date-time-main">
    <div class="elv-ledger-cell-date-time-main-top">
      {{
        dayjs(props.params.data?.datetime)
          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
          .format('YYYY/MM/DD')
      }}
    </div>

    <div
      v-if="props.params.data?.journal?.journalNo"
      class="elv-ledger-cell-date-time-main-bottom"
      @click="onClickedCopy(props.params.data?.journal?.journalNo)"
    >
      <div
        class="elv-ledger-cell-date-time-main-bottom__txHash"
        :data-clipboard-text="props.params.data?.journal?.journalNo"
      >
        {{ props.params.data?.journal?.journalNo }}
      </div>
      <img src="@/assets/img/copy.png" alt="" class="elv-ledger-cell-date-time-main-bottom__copy" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'

const { t } = useI18n()
const { toClipboard } = useClipboard()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)

const entityStore = useEntityStore()

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss">
.elv-financials-ledger-cell-group {
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.elv-ledger-cell-date-time-main {
  .elv-ledger-cell-date-time-main-top {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
  }

  .elv-ledger-cell-date-time-main-bottom {
    display: flex;
    align-items: center;
    cursor: pointer;

    .elv-ledger-cell-date-time-main-bottom__txHash {
      height: 16px;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      line-height: 16px;
      color: $elv-color-838d95;
    }

    .elv-ledger-cell-date-time-main-bottom__copy {
      padding-left: 4px;
      width: 10px;
      height: 10px;
    }
  }
}
</style>
